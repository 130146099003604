.center{
    text-align: center !important;
  }
  
  .mainContainer{
    margin: 0px !important;
    text-align: center;
  }
  
  .row{
    margin: 5%;
  }
  
  
  .uploadCard{
    width: 80%;
    display: inline-block;
  }
  
  .image{
    display: inline-block;
  }
  
  
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .btn {
    border: 2px solid #fff;
    color: #fff;
    background-color: #000;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }