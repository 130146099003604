.hero-main {
  background: linear-gradient(#000, #350101, rgb(37, 2, 2));
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;

}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
 }


.soft{
  color: rgb(228, 207, 23);
}

/* Hero */
.hero {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: url(images/bg-dot.png), url(images/bg-dot.png);
  background-position: 20px 20px, bottom 215px right 10px, left 55% top -1%, left 45% bottom -1px;
  background-repeat: no-repeat;
}
.curveImg {
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;
}
.upImg {
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
}
.hero .row {
  display: flex;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding: 10px 20px;
  
}

.hero_img {
  width: 90%;
  z-index: 10;
  position: relative;

  animation: christotleX;
  animation-duration: 3s;
  animation-timing-function: ease-out;
}

@keyframes christotleX{
  from{margin-left: 100%;}
  to{margin-right: 0%;}
}

#menu_toggle {
  display: none;
}

/* Reponsive */
@media (width < 860px) {
  
  .hero {
    padding-top: 130px;
  }
  .hero .row {
    flex-direction: column;
    padding: 0 20px;
    justify-content: center;
  }
  
}

@media (width < 600px) {
  .hero {
    padding-top: 80px;
  }
  
}