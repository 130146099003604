/* background animate */
@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
  }
  
  .backgroundu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: linear-gradient(#37263b, #c5b7cb, rgb(233, 227, 232));
    overflow: hidden;
    z-index: -1;
  }
  .backgroundu li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 19s linear infinite;
  }
  
  
  
  
  .backgroundu li:nth-child(0) {
    left: 65%;
    width: 97px;
    height: 97px;
    bottom: -97px;
    animation-delay: 1s;
  }
  .backgroundu li:nth-child(1) {
    left: 7%;
    width: 95px;
    height: 95px;
    bottom: -95px;
    animation-delay: 2s;
  }
  .background li:nth-child(2) {
    left: 41%;
    width: 135px;
    height: 135px;
    bottom: -135px;
    animation-delay: 4s;
  }
  .backgroundu li:nth-child(3) {
    left: 36%;
    width: 111px;
    height: 111px;
    bottom: -111px;
    animation-delay: 3s;
  }
  .backgroundu li:nth-child(4) {
    left: 35%;
    width: 140px;
    height: 140px;
    bottom: -140px;
    animation-delay: 3s;
  }
  .backgroundu li:nth-child(5) {
    left: 60%;
    width: 99px;
    height: 99px;
    bottom: -99px;
    animation-delay: 11s;
  }
  .backgroundu li:nth-child(6) {
    left: 74%;
    width: 104px;
    height: 104px;
    bottom: -104px;
    animation-delay: 14s;
  }
  .backgroundu li:nth-child(7) {
    left: 79%;
    width: 105px;
    height: 105px;
    bottom: -105px;
    animation-delay: 3s;
  }
  .backgroundu li:nth-child(8) {
    left: 51%;
    width: 79px;
    height: 79px;
    bottom: -79px;
    animation-delay: 6s;
  }
  .backgroundu li:nth-child(9) {
    left: 69%;
    width: 114px;
    height: 114px;
    bottom: -114px;
    animation-delay: 17s;
  }
  .backgroundu li:nth-child(10) {
    left: 71%;
    width: 84px;
    height: 84px;
    bottom: -84px;
    animation-delay: 13s;
  }
  .backgroundu li:nth-child(11) {
    left: 89%;
    width: 132px;
    height: 132px;
    bottom: -132px;
    animation-delay: 34s;
  }
  .backgroundu li:nth-child(12) {
    left: 2%;
    width: 98px;
    height: 98px;
    bottom: -98px;
    animation-delay: 6s;
  }