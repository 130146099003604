/* COMPANIES */
.marquee {
    overflow: hidden;
    padding: 1rem 0;
}
.marquee__inner {
    
    display: flex;
    align-items: center;
    /* gap: 2vw; */
    justify-content: space-between;
    flex-wrap: no-wrap;

    animation: scrolling 10s linear infinite;
}
@keyframes scrolling {
    0% {
        transform: translateX(0vw);
    }
    100% {
        transform: translateX(-100vw);
    }
}
.marquee__inner img {
    width: 20vw;
    padding: 0 3vw;
    flex-shrink: 0;
}

/* Reponsive */
@media screen and (max-width: 768px) {
  
    .companies{
        justify-content: center;
        gap: 5rem
    }
    
  }

  @media screen and (max-width: 425px) {
  
    .companies{
       padding-bottom: 5rem
    }
    
  }