.contact {
  background: linear-gradient(#000, #350101, rgb(158, 148, 3));
  padding: 60px 30px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
}

.contt{
  margin-top: 40px;
}
.contact img {
  width: 95%;
}

.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
  border-radius: 10px;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
  border-radius: 10px;
}
.align-items-center{
  display: flex;
}
.cont-left{
  width: 50%;
}
.cont-right{
  width: 50%;
}

@media (min-width: 360px) and (max-width: 768px){
  .align-items-center {
    display: block;
}
.cont-left{
  width: 95%;
  padding: 10px;
}
.cont-right{
  width: 95%;
  padding: 10px;
}
}